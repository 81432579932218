import { Formik, Form, Field } from 'formik';
import { navigate } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '../components/Button';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import styles from './contact.module.css';

export default function ContactPage() {
  const hasTouch =
    typeof window !== 'undefined'
      ? window.sessionStorage.getItem('hasTouch') === 'true'
      : false;

  return (
    <Layout>
      <Helmet>
        <title>Wealthie — Contact</title>
      </Helmet>
      <Hero title="Questions?">
        <p>Drop us a line. We will reply as soon as possible!</p>
        <p>
          Don&apos;t like forms? Send us an{' '}
          <a href="mailto:support@wealthie.app">email</a>.
        </p>
        <Formik
          initialValues={{ name: '', email: '', message: '' }}
          onSubmit={async ({ name, email, message }, { setSubmitting }) => {
            const body = JSON.stringify({
              action: 'contact',
              data: [email, name, message],
            });

            const response = await fetch(
              'https://script.google.com/macros/s/AKfycbxAXv-dD1iuT20bOn_nm5gTaQNiQpSWRrkiQJ8aG1Tgq_1D8CKWJvsxKtTruuVQwvqeiQ/exec',
              {
                method: 'post',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                  'Content-Type': 'text/plain;charset=utf-8',
                },
                body,
              },
            );

            const responseJson = await response.json();
            setSubmitting(false);
            navigate('/contact-confirmation');
          }}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form}>
              <div className={styles.formFields}>
                <label htmlFor="formName">Name:</label>
                <Field
                  id="formName"
                  type="text"
                  name="name"
                  autoFocus={!hasTouch}
                  required
                />
                <label htmlFor="formEmail">Email:</label>
                <Field id="formEmail" type="email" name="email" required />
                <label htmlFor="formMessage">Message:</label>
                <Field
                  id="formMessage"
                  type="text"
                  name="message"
                  as="textarea"
                  required
                />
              </div>
              <Button
                isLoading={isSubmitting}
                size="large"
                type="submit"
                variant="primary"
              >
                Send
              </Button>
            </Form>
          )}
        </Formik>
      </Hero>
    </Layout>
  );
}
